import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { compact, get, last } from "lodash";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import { makeUrl, removePreloader } from "../../helpers";
import { Video } from "../../components/Media";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import { MetaIsFamilyFriendly, MetaTranscript, MetaDuration, MetaName, MetaImage, MetaUploadDate, MetaDescription } from "../../components/Meta";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { VIDEO, VIDEO_MAX_UPDATE } from "../../queries/queries.graphql";

export const query = graphql`
	query videosDataQuery($slug: String!) {
		hasura {
			...MediaVideo
		}
	}
`;

export default function VideoPage({ data, location }) {
	const path = last(compact(get(location, "pathname", "").split("/")));

	const [videoData, setVideoData] = useState(get(data, "hasura.media_videos[0]", []));

	const maxUpdated = new Date(get(data, "hasura.media_videos_aggregate.aggregate.max.updated_at", new Date()));

	const videoOptions = {
		fetchPolicy: "no-cache",
		variables: {
			location: path,
		},
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(VIDEO_MAX_UPDATE, videoOptions);
	const [loadVideo, { loading: dataLoading, called, data: fetchData, error: dataError }] = useLazyQuery(VIDEO, videoOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "media_videos_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadVideo();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load VideoMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (called && fetchData && !dataError) {
			setVideoData(get(fetchData, "media_videos[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const {
		slug,
		title_full,
		content_blocks,
		src,
		main_image_preview,
		main_image,
		page_title_short,
		page_title_full,
		created_at,
		teaser,
		kind,
	} = videoData;

	const breadcrumbsData = {
		item: {
			title_full: "медиатека",
			slug: "media",
		},
		item2: {
			title_full: "видео",
			slug: "",
		},
	};

	const url = makeUrl.video({ slug });
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));

	useEffect(() => {
		removePreloader();
	}, []);

	return (
		<Pages entity={videoData} url={url} >
			<div itemScope itemType="https://schema.org/VideoObject">
				<MetaDescription content={teaser || title_full || page_title_full} />
				<MetaUploadDate content={created_at} />
				<MetaImage content={get(main_image, "src", "")} />
				<link itemProp="thumbnail" href={get(main_image, "src", "")} />
				<link itemProp="thumbnailUrl" href={get(main_image, "src", "")} />
				<link itemProp="url" href={url} />
				<MetaName content={page_title_full} />
				<MetaDuration content={get(videoData, "duration", "T20M33S")} />
				<MetaTranscript content={"VideoObject"} />
				<MetaIsFamilyFriendly content={"true"} />
				<ContentBlock key={"breadcrumbs"}>
					<div className="container">
						<Breadcrumbs currentLabel={title_full} pageContext={breadcrumbsData} />
					</div>
				</ContentBlock>
				<ContentBlock
					mount={get(data, "hasura.media_videos", "") && !!get(data, "hasura.media_videos", []).length}
					key={"videoElement"}
				>
					<div className={"container"}>
						<TextBlock title={title_full}>
							<Video videoSrcURL={src} kind={kind} className={"col-12"} />
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"video-content"} mount={content_blocks && !!content_blocks.length}>
					<div className={"container"}>
						<Contents items={content_blocks}/>
					</div>
				</ContentBlock>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	);
}

VideoPage.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
	location: PropTypes.object,
};

VideoPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
